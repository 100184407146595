h1 {
  text-align: center;
  margin-bottom: 15px;
}

.Login {
  width: 70%;
  padding: 100px;
  margin: auto;
}

.Button {
  float: left;
  margin-left: 10px;
}

.Link {
  text-decoration: none;
}

@media (max-width: 750px) {
  .Login {
    width: 100%;
    padding: 4rem 2rem;
    margin: auto;
  }

  .Button {
    float: left;
    margin-left: 10px;
  }
}
