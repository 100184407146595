.delete {
  padding: 10px;
}

.delete .flex-col {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 0 3px 3px rgb(0 0 0 / 40%);
  border-top: 2px solid #a6c8cc;
  border-radius: 5px;
}

.delete p.title {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-family: initial;
  color: #000;
  padding: 5px 12px;
  margin: 0;
  text-align: center;
}

.delete .img-fluid {
  padding: 5px;
}

.delete .content {
  padding: 5px;
  margin: 0;
  position: relative;
}

.delete .content .overlay {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  transition: all linear 0.3s;
}

.delete .content .hovereffect {
  position: absolute;
  width: 242.03px;
  background: #fff;
  height: 172px;
  overflow: hidden;
  transform: scale(0);
  transition: all linear 0.3s;
}

.delete .content .overlay:hover {
  cursor: pointer;
  transform: scale(0);
}

.delete .content .overlay:hover + .hovereffect {
  transform: scale(1);
}

.delete .content p {
  display: inline-block;
  font-size: 16px;
  color: #000;
  width: 100%;
  padding: 5px;
  margin: 0;
}

.delete .content p small {
  font-size: 16px;
  color: #005b7f;
}

.delete .btn-set {
  padding: 0 5px 5px;
  display: flex;
  justify-content: space-between;
}

.delete .btn-set button {
  margin: 0;
  width: 49%;
  padding: 5px 7px;
  color: #000;
  background: #fff;
  border: 1px solid #a6c8cc;
  transition: all linear 0.3s;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 0 0;
}

.delete .btn-set button:hover {
  background: #a6c8cc;
  color: #fff;
}
