.Preloader {
  margin: auto;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
