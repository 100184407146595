.addnew {
  margin-top: 10px;
}

.addnew h2 {
  font-size: 22px;
  display: inline-block;
  font-family: initial;
  margin: 0 0 0;
  padding: 7px 10px;
}

.nav-up {
  transform: translateY(-100px);
  opacity: 0;
}

li.sty {
  padding-left: 10px;
  font-size: 18px;
  display: inline;
  text-align: left;
  text-transform: uppercase;
  padding-right: 10px;
  color: #ffffff;
}

.signin-active a {
  padding-bottom: 10px;
  color: #1059ff;
  text-decoration: none;
  border-bottom: solid 2px #1059ff;
  transition: all 0.25s ease;
  cursor: pointer;
}

.signin-inactive a {
  padding-bottom: 10px;
  color: #1059ff;
  text-decoration: none;
  border-bottom: none;
  cursor: pointer;
  border-bottom: solid 2px transparent;
}

.signup-active a {
  cursor: pointer;
  padding-bottom: 10px;
  color: #1059ff;
  text-decoration: none;
  border-bottom: solid 2px #1059ff;
  padding-bottom: 10px;
}

.signup-inactive a {
  cursor: pointer;
  padding-bottom: 10px;
  color: #1059ff;
  border-bottom: solid 2px transparent;
  text-decoration: none;
  transition: all 0.25s ease;
}
/* 
.ht {
  height: 440px;
}

.form-signin {
  width: 500px;
  height: 400px;
  font-size: 16px;
  font-weight: 300;
  padding: 15px 70px 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.form-signin-left {
  transform: translateX(-400px);
  opacity: 0;
}

.form-signup {
  width: 500px;
  height: 400px;
  font-size: 16px;
  font-weight: 300;
  padding: 5px 50px 0;
  position: relative;
  top: -375px;
  left: 400px;
  opacity: 0;
  transition: all 0.5s ease;
}

.form-signup-left {
  transform: translateX(-399px);
  opacity: 1;
}

.form-signup-down {
  top: 0px;
  opacity: 0;
}

.form-signin input,
.form-signup input {
  color: #212529;
  font-size: 13px;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  display: none;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 75px;
  padding-top: 2px;
  cursor: pointer;
  margin-top: 8px;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  width: 65px;
  height: 30px;
  background: #7d7d7d;
  border-radius: 15px;
  left: 0;
  top: 1px;
  transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  top: 10px;
  left: 10px;
  transition: all 0.2s ease;
}

[type="checkbox"]:checked + label:before {
  background: #0f4fe6;
}

[type="checkbox"]:checked + label:after {
  background: #ffffff;
  top: 10px;
  left: 45px;
}

[type="checkbox"]:checked + label .ui,
[type="checkbox"]:not(:checked) + label .ui:before,
[type="checkbox"]:checked + label .ui:after {
  position: absolute;
  left: 6px;
  width: 65px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked) + label .ui:before {
  content: "no";
  left: 32px;
  color: rgba(255, 255, 255, 0.7);
  top: 3px;
}

[type="checkbox"]:checked + label .ui:after {
  content: "yes";
  color: #ffffff;
  top: 2px;
}

[type="checkbox"]:focus + label:before {
  box-sizing: border-box;
  margin-top: -1px;
} */

.addnew label {
  margin: 0 0 15px;
  font-size: 20px;
  font-family: initial;
}
.addnew input {
  width: 100%;
  border: none;
  padding: 7px 12px;
  font-size: 16px;
  margin: 0 0 15px;
  border-radius: 5px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}

.upload-img {
  position: relative;
  margin: 0 0 15px;
  max-width: 250px;
  min-height: 200px;
  text-align: center;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}
.upload-img input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  overflow: hidden;
  opacity: 0;
}

.upload-img label {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  background-image: url();
  background-size: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}
.upload-img label span {
  background-color: rgba(36, 65, 231, 0.9);
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: auto;
  padding: 1px;
  opacity: 0;
  position: absolute;
  width: 35px;
  height: 35px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
  transition: all linear 0.3;
}

.upload-img:hover label span {
  opacity: 1;
}

/* .addnew button.publish {
  margin-right: 25px;
} */

.addnew button {
  border: 1px solid #005b7f;
  padding: 5px 12px;
  float: right;
  margin: 5px 10px;
  outline: none;
  width: 150px;
  border-radius: 5px;
  transition: all ease 0.3s;
}
.addnew button:hover {
  background: #005b7f;
  color: #fff;
}
