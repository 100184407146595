.addnew {
  margin-top: 10px;
}

.addnew h2 {
  font-size: 22px;
  display: inline-block;
  font-family: initial;
  margin: 0 0 0;
  padding: 7px 10px;
}

.nav-up {
  transform: translateY(-100px);
  opacity: 0;
}

li.sty {
  padding-left: 10px;
  font-size: 18px;
  display: inline;
  text-align: left;
  text-transform: uppercase;
  padding-right: 10px;
  color: #ffffff;
}

.addnew label {
  margin: 0 0 15px;
  font-size: 20px;
  font-family: initial;
}
.addnew input {
  width: 100%;
  border: none;
  padding: 7px 12px;
  font-size: 16px;
  margin: 0 0 15px;
  border-radius: 5px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}

.upload-img {
  position: relative;
  margin: 0 0 15px;
  max-width: 250px;
  min-height: 200px;
  text-align: center;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}
.upload-img input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  overflow: hidden;
  opacity: 0;
}

.upload-img label {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  background-image: url();
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}
.upload-img label span {
  background-color: rgba(36, 65, 231, 0.9);
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: auto;
  padding: 1px;
  opacity: 0;
  position: absolute;
  width: 35px;
  height: 35px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
  transition: all linear 0.3;
}

.upload-img:hover label span {
  opacity: 1;
}

/* .addnew button.publish {
  margin-right: 25px;
} */

.addnew button {
  border: 1px solid #005b7f;
  padding: 5px 12px;
  float: right;
  margin: 5px 10px 15px;
  outline: none;
  width: 150px;
  border-radius: 5px;
  transition: all ease 0.3s;
}
.addnew button:hover {
  background: #005b7f;
  color: #fff;
}

.addnew .container-fluid {
  padding: 15px;
}

.container-fluid input[type="text"] {
  width: 200px;
  display: block;
}

.addnew .subcs {
  margin: 0 0 5px;
}

.subcs p {
  font-size: 20px;
  font-weight: 500;
  font-family: initial;
  padding: 7px;
  margin: 0;
}

.subcs .scrollview {
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border: 1px solid #005b7f;
  border-radius: 5px;
}

.scrollview .styles {
  position: relative;
  display: inline-block;
  white-space: pre-wrap;
  width: 100px;
  margin: 0 5px;
  border: 1px solid #a6c8cc;
}

.scrollview > .styles:first-child {
  margin-left: 0;
}

.scrollview .styles input[type="checkbox"] {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none;
  width: initial;
  margin: 5px;
}

.scrollview .styles .stretched {
  position: absolute;
  cursor: pointer;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  margin: 0;
  top: 0;
  left: 0;
}

.styles .img-fluid {
  height: 80px;
  display: inline-block;
}

.styles p.sname {
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  margin: 0;
  display: inline-block;
  width: 100%;
  text-align: center;
}
