.info_box {
  position: relative;
  width: 30%;
  padding: 10px;
  background: #a6c8cc;
}

.info_box .goback {
  position: absolute;
  top: 20px;
  left: 0;
  background: transparent;
  border: none;
  padding: 5px;
  width: 50px;
}

.info_box .heading {
  font-size: 22px;
  margin: 0;
}
@keyframes info_box {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.info_box .heading,
.info_box .content {
  -webkit-animation-name: team-info-item;
  animation-name: team-info-item;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.info_box .content {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.info_box .content {
  font-size: 19px;
  font-weight: 300;
  line-height: 1.5;
  color: #000;
  margin-top: 15px;
}
.content .wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.content .wrap .img-fluid {
  width: 50%;
}
.content .wrap .btn-set {
  width: 50%;
  padding: 0p1xpx;
}
.content .wrap .btn-set button {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #efefef;
  background-color: #fff;
  color: #1b1212;
  outline: none;
  font-size: 16px;
  font-family: initial;
  transition: all ease 0.3s;
  border-radius: 5px;
}
.content .wrap .btn-set button:hover {
  background-color: #efefef;
}
.content .count {
  padding: 25px 10px 10px;
}
.content .count p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.view-all button {
  width: 100%;
  display: inline-block;
  padding: 7px 12px;
  color: #1b1212;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #efefef;
  text-decoration: none;
  font-size: 20px;
  font-family: initial;
  margin: 0 0 10px;
  transition: all ease 0.3s;
}
.view-all button:hover {
  background-color: #efefef;
}

/* code for hide or show */

.can-toggle {
  position: relative;
}
.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  padding: 0 !important;
  margin: 5px 0 !important;
  user-select: none !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #4fb743;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #5fc054;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #47a43d;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #a6c8cc;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  transform: translate3d(57px, 0, 0);
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 1;
  margin: 5px 0 !important;
  user-select: none !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #4fb743;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #5fc054;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #47a43d;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #a6c8cc;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #a6c8cc;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  transform: translate3d(57px, 0, 0);
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 1;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 57px;
  font-size: 12px;
  line-height: 36px;
  width: 50%;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 50%;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  transform: translate3d(44px, 0, 0);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  flex: 0 0 90px;
  border-radius: 2px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
