.user {
  padding: 10px;
}

.user .rflex {
  justify-content: flex-start;
}

.user .userdetails {
  margin: 0;
  border: 1px solid #005b7f;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 3px 0px;
}

.user .nav-tabs .nav-item {
  margin: 0 0 10px;
}

.user .nav-tabs .nav-item .nav-link {
  font-size: 28px;
  font-weight: 600;
  color: #0a0a0a;
  background: #fff;
  padding: 8px 15px;
  border: none !important;
}

.user .nav-tabs .nav-item .active {
  background: #a6c8cc;
}

/* =================== */

.userdetails {
  position: relative;
  margin: 0 0 10px;
}

.userdetails .box {
  display: flex;
  margin: 0 0 10px;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #a6c8cc;
}

.userdetails > .box:last-child {
  margin: 0;
}

.userdetails .head {
  border: 1px solid #005b7f;
  margin: 0 0 10px;
  /* border-radius: 6px; */
}

.userdetails .head p {
  font-size: 20px;
  /* font-weight: 200; */
  padding: 7px;
  margin: 0;
  text-align: center;
  border-right: 1px solid #005b7f;
}

.userdetails .list p {
  font-size: 16px;
  /* font-weight: 600; */
  padding: 7px;
  margin: 0;
  text-align: center;
  border-right: 1px solid #a6c8cc;
}

.userdetails .list .block {
  width: 10%;
  background: #005b7f;
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
}

.userdetails .list .unblock {
  width: 10%;
  background: #005b7f;
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
}

.userdetails .box p.id {
  width: 15%;
}

.userdetails .box p.tname {
  width: 30%;
}

.userdetails .box p.no {
  width: 30%;
}

.userdetails .box p.spec {
  width: 30%;
}

.userdetails .box p.add {
  width: 10%;
  border-right: 0;
}
