.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 2vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.form {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 35rem;
  }
}
