.orderdetail {
  padding: 10px;
  position: relative;
}

.status {
  width: 100%;
  padding: 10px;
}

.summary {
  /* border: 1px solid #a6c8cc; */
  padding: 0 0 5px;
  list-style: none;
}

.summary::-webkit-details-marker {
  display: none;
}
.no {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 45px;
  height: 45px;
  line-height: 33px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}
.back {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 50%;
  text-align: center;
  border: none;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.heading {
  text-align: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.row1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0 0;
}

.col61 {
  width: 65%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
}

.col6 {
  width: 35%;
  padding: 0 5px 5px;
}

.title {
  padding: 8px 5px;
  border-bottom: 1px solid;
  margin: 0 10px;
}

.title1 {
  padding: 8px 5px;
  border-bottom: 1px solid;
  margin: 0 10px 5px;
}

.userd {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 49%;
  height: initial;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.priced {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 49%;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.descrb {
  width: 49%;
  height: initial;
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.tailord {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 49%;
  height: initial;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.deld {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 42%;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.altd {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 49%;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.mesd {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}
.mescloth {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}
.scloth {
  display: flex;
  margin: 0 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.mpi {
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.api {
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.umi {
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.uai {
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.fi {
  width: 100%;
  margin: 0 0 10px;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 18%);
}

.para {
  margin: 0;
  padding: 5px 15px;
}

.innerdiv {
  margin: 0;
  padding: 0 10px 5px;
  max-height: 200px;
  overflow-y: auto;
}

.innerdiv::-webkit-scrollbar {
  width: 5px;
}

.innerdiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

.innerdiv::-webkit-scrollbar-thumb {
  background: #005b7f;
}

.columns {
  width: 50% !important;
  padding: 15px;
  position: relative;
}

.mesp {
  margin: 0 0 5px;
}

.span {
  width: 75%;
  display: inline-block;
  padding: 0 5px 5px;
}

.footer {
  width: 100%;
  padding: 10px 0 0;
  text-align: right;
}

.acpt {
  font-size: 16px;
  color: green;
  padding: 5px 10px;
  margin-right: 8px;
  width: 150px;
  border: 1px solid green;
  outline: none;
  text-align: center;
  transition: all ease 0.3s;
}

.acpt:hover {
  color: #fff;
  background: green;
}

.cncl {
  font-size: 16px;
  color: red;
  padding: 5px 10px;
  width: 150px;
  border: 1px solid red;
  outline: none;
  text-align: center;
  transition: all ease 0.3s;
}

.cncl:hover {
  color: #fff;
  background: red;
}
