.swiper-slide:hover {
  cursor: ew-resize;
}

.swiper-container {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.main-single-view {
  flex-basis: 500px;
  width: 70%;
}

.arrow-box {
  position: fixed;
  width: 24%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.16);
  padding: 5px 35px;
  margin: 0 20px 12px;
}
.arrow-box .slideNext,
.arrow-box .slidePrev {
  transition: all 0.3s;
  cursor: pointer;
}
.arrow-box .slidePrev:hover {
  transform: translateX(-10px);
}
.arrow-box .slideNext:hover {
  transform: translateX(10px);
}
.arrow-box svg {
  width: 30px;
}
