.info_box {
  position: relative;
  width: 30%;
  padding: 10px;
  background: #a6c8cc;
}

.info_box .goback {
  position: absolute;
  top: 20px;
  left: 0;
  background: transparent;
  border: none;
  padding: 5px;
  width: 50px;
  transition: 0.3s;
}

.info_box .goback:hover {
  transform: scale(1.3);
}

.info_box .heading {
  font-size: 22px;
  margin: 0;
}

@keyframes info_box {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.info_box .heading,
.info_box .content {
  -webkit-animation-name: team-info-item;
  animation-name: team-info-item;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.info_box .content {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.info_box .content {
  font-size: 19px;
  font-weight: 300;
  line-height: 1.5;
  color: #000;
  margin-top: 15px;
}
.content .wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.content .wrap .img-fluid {
  width: 50%;
}
.content .wrap .btn-set {
  width: 50%;
  padding: 0 10px;
}
.content .wrap .btn-set button {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #efefef;
  background-color: #fff;
  color: #1b1212;
  outline: none;
  font-size: 16px;
  font-family: initial;
  transition: all ease 0.3s;
  border-radius: 5px;
}
.content .wrap .btn-set button:hover {
  background-color: #efefef;
}
.content .count {
  padding: 25px 10px 10px;
}
.content .count p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.view-all button {
  width: 100%;
  display: inline-block;
  padding: 7px 12px;
  color: #1b1212;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #efefef;
  text-decoration: none;
  font-size: 20px;
  font-family: initial;
  margin: 0 0 10px;
  transition: all ease 0.3s;
}
.view-all button:hover {
  background-color: #efefef;
}
