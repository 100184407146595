.ordernav {
  padding: 0;
  margin: 10px auto;
  width: 856px;
  height: 47px;
  /* border-bottom: 1px solid #a6c8cc; */
}

.ordernav a {
  font-size: 18px;
  color: #005b7f;
  font-family: initial;
  display: inline-block;
  padding: 10px;
  margin: 0 2px;
  width: 210px;
  height: 100%;
  border: 2px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transform: scale(0.8);
  transition: all ease 0.5s;
}

.ordernav a:hover {
  color: #fff !important;
  background-color: #a6c8cc;
  transform: scale(1);
  border: 2px solid #a6c8cc;
}

.activeLink {
  color: #fff !important;
  background-color: #a6c8cc;
  border: 2px solid #a6c8cc;
}

.ordercontent {
  border: 1px solid #005b7f;
  padding: 10px;
  margin: 5px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 3px 0px;
}
