.rflex {
  display: flex;
  margin: 0 0 10px;
  /* flex-wrap: wrap; */
  justify-content: center;
}

button {
  font-size: 18px;
  padding: 5px 10px;
  color: #000;
  border: 5px solid #efefef;
  width: 150px;
  text-align: center;
}

.active {
  color: #fff;
  background-color: #005b7f;
  border: 5px solid #efefef;
}
/* Order View */

.summary {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 5px;
  border: 1px solid #a6c8cc;
  border-radius: 5px;
  cursor: default;
}

.summary p {
  font-size: 16px;
  display: inline-block;
  padding: 10px 5px;
  margin: 0;
  border-right: 1px solid #a6c8cc;
  text-align: center;
}

.summary p.ono {
  width: 8%;
}

.summary > p:last-child {
  border-right: none;
  cursor: pointer;
}

.summary .anchor {
  font-size: 16px;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 7%;
  text-align: center;
}

.summary .anchor i.far {
  font-size: 30px;
  color: #005b7f;
  padding: 8px;
  cursor: pointer;
}

/* received && processing */

.received p.cat {
  width: 23%;
}

.received p.tname {
  width: 23%;
}

.received p.tno {
  width: 19%;
}

.received p.ddate {
  width: 20%;
}

/* repick */

.repick p.cat {
  width: 15%;
}

.repick p.tname {
  width: 17%;
}

.repick p.tno {
  width: 15%;
}

.repick p.cname {
  width: 15%;
}

.repick p.cno {
  width: 15%;
}

.repick p.select {
  width: 15%;
  padding: 10px 5px;
}
.repick p.accept {
  width: 8%;
  cursor: pointer;
}

.repick select {
  font-size: 16px;
  border: none;
  width: 100%;
  height: 100%;
  outline-color: transparent;
  cursor: pointer;
}

/* finished */

.finished p.cat {
  width: 15%;
}

.finished p.tname {
  width: 19%;
}

.finished p.tno {
  width: 15%;
}

.finished p.ddate {
  width: 20%;
}

.finished p.accept,
.finished p.reject {
  width: 8%;
  cursor: pointer;
}

.finished p.accept {
  color: green;
}

.finished p.reject {
  color: red;
}

.head {
  border: 2px solid #005b7f;
}

.head p,
.head .anchor {
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid #005b7f;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  justify-content: center;
}
