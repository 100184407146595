.info {
  width: 70%;
  padding: 10px 10px 0;
}
.info .flex {
  display: flex;
  margin: 0 0 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.left button {
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  width: 85px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  border-radius: 5px;
}

.left button.active {
  color: #005b7f;
  border: 1px solid;
  border-bottom: 5px solid transparent;
}

.left > button:first-child {
  margin-left: -5px;
}
.right input {
  padding: 5px 12px;
  margin: 0 0 10px;
  font-size: 16px;
  border: 1px solid #005b7f;
  border-radius: 5px;
  outline: none;
  outline-offset: 0;
}
.info .content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 1px solid #005b7f;
  border-radius: 5px;
  padding: 10px 5px;
  height: 580px;
  overflow: auto;
}
.content .col-3 {
  padding: 5px;
}
.content_box {
  position: relative;
  padding: 0;
  margin: 0;
  height: 175px;
  border: 1px solid #efefef;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  transition: all ease 0.3s;
}
.content_box:hover {
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.content_box .img_fluid {
  width: 100%;
  height: auto;
}
.content_box .bottom-row {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #a6c8cc;
}

.content_box .bottom-row p.pname {
  padding: 5px;
  font-size: 16px;
  color: #000000;
  margin: 0;
  font-family: initial;
}
