.addnew {
  margin-top: 10px;
}

.addnew h2 {
  font-size: 22px;
  display: inline-block;
  font-family: initial;
  margin: 0 0 0;
  padding: 7px 15px;
}

.addnew .nav-tabs {
  border-bottom: none;
}

.addnew .nav-tabs .nav-item .nav-link {
  color: #000;
  border: none;
  text-decoration: none;
  transition: all 0.3s ease;
}

.addnew .nav-tabs .nav-item .active {
  color: #005b7f;
  border: 0px;
  padding-bottom: 10px;
  border-bottom: solid 2px #1059ff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.addnew .tab-content .container {
  padding: 10px;
  background: transparent;
  border: none;
  color: #0a0a0a;
}

.addnew label {
  margin: 0 0 15px;
  font-size: 20px;
  font-family: initial;
}
.addnew input {
  width: 100%;
  border: none;
  padding: 7px 12px;
  font-size: 16px;
  margin: 0 0 15px;
  border-radius: 5px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}

.addnew select {
  width: 100%;
  border: none;
  padding: 7px 12px;
  font-size: 16px;
  margin: 0 0 15px;
  border-radius: 5px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}

.addnew select:hover {
  cursor: pointer;
}

.addnew select:focus {
  outline: none;
}

.upload-img {
  position: relative;
  margin: 0 0 15px;
  max-width: 250px;
  min-height: 200px;
  text-align: center;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
}
.upload-img input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  overflow: hidden;
  opacity: 0;
}

.upload-img label {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  background-image: url();
  background-size: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}
.upload-img label span {
  background-color: rgba(36, 65, 231, 0.9);
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: auto;
  padding: 1px;
  opacity: 0;
  position: absolute;
  width: 35px;
  height: 35px;
  box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.16);
  transition: all linear 0.3;
}

.upload-img:hover label span {
  opacity: 1;
}

/* .addnew button.publish {
  margin-right: 25px;
} */

.addnew button {
  border: 1px solid #005b7f;
  padding: 5px 12px;
  float: right;
  margin: 5px 10px 20px;
  outline: none;
  width: 150px;
  border-radius: 5px;
  transition: all ease 0.3s;
}
.addnew button:hover {
  background: #005b7f;
  color: #fff;
}
.choices {
  padding: 5px 20px;
}
.choices input[type="radio"] {
  width: initial;
  margin: 0;
  box-shadow: 0 0 0;
}
.choices label {
  width: initial;
  margin: 0;
}
