.rflex button.selected {
  color: #fff;
  background-color: #005b7f;
  border: 5px solid #efefef;
}

/* Order View */

.rflex {
  display: flex;
  margin: 0 0 10px;
  /* flex-wrap: wrap; */
  justify-content: center;
}

button {
  position: relative;
  font-size: 18px;
  padding: 5px 10px;
  color: #000;
  border: 5px solid #efefef;
  width: 150px;
  text-align: center;
}

.rflex button .new-count {
  position: absolute;
  color: #000;
  background: #fff;
  top: -10px;
  right: 7px;
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 50%);
}

.rflex button.active {
  color: #fff;
  background-color: #005b7f;
  border: 5px solid #efefef;
}
/* Order View */

.summary {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 5px;
  border: 1px solid #a6c8cc;
  border-radius: 5px;
  cursor: default;
}

.summary p {
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  padding: 10px 5px;
  margin: 0;
  border-right: 1px solid #a6c8cc;
  overflow: hidden;
  text-align: center;
}

.summary p.ono {
  width: 8%;
  text-align: center;
}

.summary > p:last-child {
  border-right: none;
  cursor: pointer;
}

.summary .anchor {
  font-size: 16px;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 7%;
  text-align: center;
}

.summary .anchor i.far {
  font-size: 30px;
  color: #005b7f;
  padding: 8px;
  cursor: pointer;
}

/* booked */
.booked p.cat {
  width: 17%;
}

.booked p.cname {
  width: 23%;
}

.booked p.cno {
  width: 19%;
}

.booked p.date {
  width: 10%;
}

.booked p.accept,
.booked p.reject {
  width: 8%;
  cursor: pointer;
}

.booked p.accept {
  color: green;
}

.booked p.accept:hover i,
.booked p.reject:hover i {
  box-shadow: 0 1px 4px #000;
  padding: 4px 5px;
}

.booked p.reject {
  color: red;
}

/* verified */

.verified p.cat {
  width: 23%;
}

.verified p.date {
  width: 20%;
}

.verified p.ddate {
  width: 22%;
}

.verified p.price {
  width: 20%;
}

/* accepted */

.accepted p.cat {
  width: 18%;
}

.accepted p.date {
  width: 17%;
}

.accepted p.ddate {
  width: 20%;
}

.accepted p.price {
  width: 20%;
}

.accepted p.assign {
  width: 10%;
  cursor: pointer;
  color: #005b7f;
}

/* assigned */

.assigned p.cat {
  width: 21%;
}

.assigned p.tname {
  width: 25%;
}

.assigned p.tno {
  width: 19%;
}

.assigned p.ddate {
  width: 20%;
}

.head {
  border: 2px solid #005b7f;
}

.head p,
.head .anchor {
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid #005b7f;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  justify-content: center;
}
